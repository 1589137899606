/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthTopNav from "components/Navbars/AuthTopNav.js";
import fire from "../../config/firebase";

class Dashboard extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      user: {}
    }
  }

  componentDidMount() {

    this.authListener();
  }

  authListener(){

    fire.auth().onAuthStateChanged((user) => {

      if(user){
        
        this.setState({user});
        // localStorage.setItem('user', user.uuid);

      }else{
        this.setState({user: null});
        // localStorage.removeItem('user');
      }

    });

  }

  render() {
    return (
      <>        
          <main className="mainAuth" ref="main">
            <div className="authContainer">
              <AuthNavbar />
              <AuthTopNav user={this.state.user} />
              <Row>
                <Col md="3">
                <Card className="shadow">
                    <CardHeader>
                      Blog Posts
                    </CardHeader>
                    <CardBody>
                      5 Posts
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                <Card className="shadow">
                    <CardHeader>
                      Projects
                    </CardHeader>
                    <CardBody>
                      2 Projects
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                <Card className="shadow">
                    <CardHeader>
                      Open Issues
                    </CardHeader>
                    <CardBody>
                      0 Open Issues
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="shadow">
                    <CardHeader>
                      Users
                    </CardHeader>
                    <CardBody>
                      1 Users
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="rowMargin">
                <Col md="8">
                  <Card className="shadow">
                    <CardHeader>
                      Create Blog Post
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Input
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                                type="email"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Input disabled placeholder="Regular" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <InputGroup className="mb-4">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-zoom-split-in" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Search" type="text" />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <InputGroup className="mb-4">
                                <Input placeholder="Birthday" type="text" />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="ni ni-zoom-split-in" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup className="has-success">
                              <Input
                                className="is-valid"
                                placeholder="Success"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="has-danger">
                              <Input
                                className="is-invalid"
                                placeholder="Error Input"
                                type="email"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="shadow">
                    <CardHeader>
                      Manage Blog Posts
                    </CardHeader>
                    <CardBody>

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </main>
      </>
    );
  }
}

export default Dashboard;
