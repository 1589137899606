/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

class Profile extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 background-detroit">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image centerHeaderImage">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="ni ni-chart-bar-32 mr-2 rounded-circle customServiceHeaderImage orangeHeaderImage" />

                        </a>
                      </div>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="mr-4"
                          color="info"
                          href="tel:+18333288477"
                          size="sm"
                        >
                          Try Us Out 1 Month FREE!
                        </Button>
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">5+</span>
                          <span className="description">Campaigns</span>
                        </div>
                        <div>
                          <span className="heading">100,000+</span>
                          <span className="description">Ad Spend</span>
                        </div>
                        <div>
                          <span className="heading">400,000+</span>
                          <span className="description">Revenue</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>
                      Dauti Digital
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Windsor, Ontario
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Digital Marketing
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      Google Ads, Facebook Ads, Instagram Ads, Content Marketing
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <h6>Some numbers</h6>
                        <p>
                          With over 5 Google campaigns, more than $100,000 in ad spend and over $400,000 generated in revenue for our clients we are sure that we can bring any business more sales and revenue. When it comes to digital marketing and advertising
                          what you know and how much experience you have in the field will always reflect on potential earnings or loss. This is why leaving the hard part to the professionals is paramount in running an effective online strategy.
                        </p>
                        <h6>Try us out for 30 days for free!</h6>
                        <p>
                          We are offering a 30 day free trial for all businesses. The offer includes a consultation and analysis of your current online presence along with a carefully planned marketing strategy that is free of charge for the first 30 days. How it works is we setup call forwarding
                          with a little message for every lead you reiceive through our marketing campaigns. This will allow you to keep track of every lead you gain from us so that at the end of the month you can clearly see the amount of sales brought to you by us. We will setup and maintain your campaigns
                          for a whole month completly free of charge. Our goal is to have each business creating more revenue then what it is costing them to receive that extra revenue, if we cannot do this then we are not doing our job. Call us today for more information.
                        </p>
                        <h6>Did you know 95% of users will only consider listings on the first page of Google?</h6>
                        <p>
                          Results show that if your business is not listed within the first 3 listings on google you will lose out on a whopping 60% of users searching for businesses like yours. Furthermore another 95% of potential customers will be lost if you
                          are not listed within the first page of listings.
                        </p>
                        <h6>What is SEO?</h6>
                        <p>
                          SEO stands for search engine optimization and is the term used for optimizing your website in order for Google web crawlers to index your website higher up the list. SEO has a lot that goes into it such as keywords that are relevent to your search terms and
                          the more of these keywords you have in your content the better you will rank on Google and other search engines. Back links are another form used to measure the relevance of your website the more external websites that link to your website the better. There is much much more
                          that we cant cover in a short paragraph , the idea is that knowing SEO is paramount in building long lasting free and relevant traffic and leads. This is why having a marketing agency that understands SEO is very important.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Profile;
