/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, Redirect} from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Card,
  CardBody,
  CardHeader,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import fire from "../../config/firebase";

class AuthNavbar extends React.Component {
  
  constructor(props){
    super(props);
    this.logout = this.logout.bind(this);
    this.state = {loggedOut: false};
  }
  
  componentDidMount() {

  }

  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  logout = () => {
    fire.auth().signOut().then((u) => {
      this.setState({loggedOut: true});
    }).catch((e) => {
      alert(e.message);
    });
  }

  render() {

    if(this.state.loggedOut === true){
      return(
        <Redirect to="/" />
      );
    }

    return (
      <>
        <Card className="sidenav shadow border-1">
          <CardHeader>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/logo.png")}
                />
            </NavbarBrand>
          </CardHeader>
          <CardBody>
            <Link to="/dashboard"><Button className="btnNav" color="info"><i className="ni ni-chart-pie-35"></i> Dashboard</Button></Link>
            <Button className="btnNav" onClick={this.logout} color="danger"><i className="ni ni-button-power"></i> Logout</Button>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default AuthNavbar;
