/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

class Profile extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 background-detroit">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image centerHeaderImage">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="ni ni-mobile-button mr-2 rounded-circle customServiceHeaderImage greenHeaderImage" />

                        </a>
                      </div>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="mr-4"
                          color="info"
                          href="tel:+18333288477"
                          size="sm"
                        >
                          Contact Us
                        </Button>
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">20+</span>
                          <span className="description">Projects</span>
                        </div>
                        <div>
                          <span className="heading">20+</span>
                          <span className="description">Clients</span>
                        </div>
                        <div>
                          <span className="heading">10,000+</span>
                          <span className="description">Hours</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>
                      Dauti Digital
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Windsor, Ontario
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Mobile Application Development
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      Android, iPhone, iPad, Objective C, Swift, Java
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <h3 className="text-center">Mobile Application Development</h3>
                        <h6 className="text-center">Android, iOS</h6>
                        <p>
                          We have experience developing an array of mobile applications accross iOS and Android devices. We can build applications for either operating system complete from design to deployment on the Google Play Store or the Apple App Store.
                          Here is a list of the types of mobile application systems we have built.
                        </p>
                        <ul>
                          <li>Fantasy Sports App</li>
                          <li>Crypto Currency Social Network</li>
                          <li>Bitcoin Wallet</li>
                          <li>Small Business Apps</li>
                          <li>Fitness App</li>
                          <li>E-Commerce App</li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Profile;
