/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import moment from "moment";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Card,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import fire from "../../config/firebase";


class AuthTopNav extends React.Component {
  render() {
    return (
      <>
        <Card className="shadow border-1 topNavAuth">
          <Row>
            <Col className="order-md-1" md="6">
              <p><i className="ni ni-single-02"></i> {this.props.user.email}</p>
            </Col>
            <Col className="order-md-1" md="6">
              <p className="topAuthDate"><i className="ni ni-calendar-grid-58"></i> {moment().format('YYYY-MM-DD')}</p>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

export default AuthTopNav;
