import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyAH-drYmLVsm1aa0-5cRi5BduOV8iXEXjc",
    authDomain: "dautidigital.firebaseapp.com",
    databaseURL: "https://dautidigital.firebaseio.com",
    projectId: "dautidigital",
    storageBucket: "dautidigital.appspot.com",
    messagingSenderId: "7805798894",
    appId: "1:7805798894:web:0447ac469898c841b7a6ff",
    measurementId: "G-615BJ6W9R1"
  };

  const fire = firebase.initializeApp(firebaseConfig);
  export default fire;