/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
   Button,
   Card,
   CardBody,
   Container,
   Row,
   Col,
   FormGroup,
   Input,
   InputGroupAddon,
   InputGroupText,
   InputGroup
} from "reactstrap";
import classnames from "classnames";
// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

class Profile extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 background-detroit">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image centerHeaderImage">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="ni ni-chart-bar-32 mr-2 rounded-circle customServiceHeaderImage orangeHeaderImage" />

                        </a>
                      </div>
                    </Col>

                  </Row>
                  <div className="text-center mt-5">
                    <h3>
                      Dauti Digital
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Windsor, Ontario
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Toll Free: 1 (833) 328-8477
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      Email: amir@dautidigital.com
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="8">
                        <Card className="bg-gradient-secondary shadow">
                          <CardBody className="p-lg-5">
                            <h4 className="mb-1">Want to work with us?</h4>
                            <p className="mt-0">
                              Your project is very important to us.
                            </p>
                            <FormGroup
                              className={classnames("mt-5", {
                                focused: this.state.nameFocused
                              })}
                            >
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-user-run" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Your name"
                                  type="text"
                                  onFocus={e => this.setState({ nameFocused: true })}
                                  onBlur={e => this.setState({ nameFocused: false })}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup
                              className={classnames({
                                focused: this.state.emailFocused
                              })}
                            >
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-email-83" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Email address"
                                  type="email"
                                  onFocus={e => this.setState({ emailFocused: true })}
                                  onBlur={e => this.setState({ emailFocused: false })}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-4">
                              <Input
                                className="form-control-alternative"
                                cols="80"
                                name="name"
                                placeholder="Type a message..."
                                rows="4"
                                type="textarea"
                              />
                            </FormGroup>
                            <div>
                              <Button
                                block
                                className="btn-round"
                                color="default"
                                size="lg"
                                type="button"
                              >
                                Send Message
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Profile;
