/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import axios from 'axios';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Landing extends React.Component {
  
  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target)
    const data = {}

    event.preventDefault()

    for (let entry of formData.entries()) {
        console.log(entry);
        data[entry[0]] = entry[1]
    }

    axios.post('/api/mail', data)
    .then(function (response) {
      alert(response.data.message);
    })
    .catch(function (error) {
      alert(error);
    });
  
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 background-detroit">

              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6" className="homeInfo">
                      <h1 className="display-3 text-white">
                        Dauti Digital
                        <span>App Development & Marketing</span>
                      </h1>
                      <p className="lead text-white">
                        We are a full-service digital marketing agency with business minded web design & development you deserve. We are located right here in the heart of Windsor, Ontario
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href="tel:+18333288477"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Lets talk shop, no commitments required.</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-laptop" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Web Development
                          </h6>
                          <p className="description mt-3">
                            We have over 10 years experience developing web applications and websites. We have built everything from small business websites up to complex social networks and financial exchange web applications.
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              Financial Websites
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Business Websites
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              ReactJS
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              JavaScript
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              NodeJS
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              PHP
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              WordPress
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Social Network
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="/web-page"
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-mobile-button" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Mobile App Development
                          </h6>
                          <p className="description mt-3">
                            Have a great app idea in mind? No problem we can bring your idea to life on both iOS and Android platforms, with our years of experience developing and maintaining mobile applications you can rest assured that your idea is in the right hands.
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              Android
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              iOS
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href="/mobile-page"

                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-chart-bar-32" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Digital Marketing
                          </h6>
                          <p className="description mt-3">
                            We run various successful digital marketing campaigns for businesses across Windsor, Ontario. We have a tremendous success rate and on average our clients see a return of 400% ROI monthly.
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              Google Ads
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              Content Marketing
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              Search Engine Optimization
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              Facebook Ads
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              Instagram Ads
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="/marketing-page"
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>UI/UX Design</h3>
                    <p>
                      We are able to bring your next app/website idea to life every step of the way, from great user experience and design to clean scalable code we got you covered.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-spaceship" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Quick turn around on design and development
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">We use the latest technologies</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Great user experience in mind with each design
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section pb-0 bg-gradient-warning">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/ill/ill-2.svg")}
                    />
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Digital Marketing</h4>
                      <p className="text-white">
                        Let us run your digital marketing campaigns and see the difference for your self.
                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Free Trial
                          </h5>
                          <p>
                            We will manage and create Google, Facebook & Instagram campaigns for free for 30 days! This allows you to see the potential customers you are missing out on due to a lack of online presence.
                          </p>
                          <a
                            className="text-success"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-active-40" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                            Google 1st Page Listing
                          </h5>
                          <p>
                           Get ahead of your competition, did you know 60% of users using search engines will only click on one of the first three listings, we guarantee all our clients listings between the first and third position or your money back.
                          </p>
                          <a
                            className="text-warning"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Portfolio</h2>
                  <p className="lead text-muted">

                  </p>
                </Col>
              </Row>
              <Row>

                <Col>
                  <span><img
                    alt="..."
                    className="img-fluid floating redlineLogo"
                    src={require("assets/img/portfolio/redline-logo.png")}
                  /></span>
                </Col>
                <Col>
                  <span><img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/portfolio/aca.PNG")}
                  /></span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span><img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/portfolio/redline-website.PNG")}
                  /></span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span><img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/portfolio/realagent.png")}
                  /></span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span><img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/portfolio/draftpool-1.png")}
                  /></span>
                </Col>
                <Col>
                  <span><img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/portfolio/draftpool-2.png")}
                  /></span>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Want to work with us?
                      </h3>
                      <p className="lead text-white mt-3">
                        Contact us today for a free consultation.
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="tel:+18333288477"
                        size="lg"
                      >
                        Call Now
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Build something special</h2>
                  <p className="lead text-white">
                    Our goal is to help each and every client gain more customers through our digital marketing and help them bring their application ideas to life in a beautiful way.
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p>
                      <form onSubmit={this.handleSubmit}>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            name="name"
                            type="text"
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            name="email"
                            type="email"
                            onFocus={e => this.setState({ emailFocused: true })}
                            onBlur={e => this.setState({ emailFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="message"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="submit"
                        >
                          Send Message
                        </Button>
                      </div>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>

        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
