/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import AuthRoutes from "./AuthRoutes.js";
import GuestRoutes from "./GuestRoutes.js";

import fire from "config/firebase.js";


class Index extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      user: {}
    }
  }

  componentDidMount() {

    this.authListener();
  }

  authListener(){

    fire.auth().onAuthStateChanged((user) => {

      if(user){
        
        this.setState({user});
        // localStorage.setItem('user', user.uuid);

      }else{
        this.setState({user: null});
        // localStorage.removeItem('user');
      }

    });

  }

  render() {
    return (
      <>
        {this.state.user ? (<AuthRoutes />) : (<GuestRoutes />) }
      </>
    );
  }
}

export default Index;
