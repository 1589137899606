/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Landing from "views/pages/Landing.js";
import Profile from "views/pages/Profile.js";
import Portfolio from "views/pages/Portfolio.js";
import Web from "views/pages/Web.js";
import Mobile from "views/pages/Mobile.js";
import Dashboard from "views/pages/Dashboard.js";
import Marketing from "views/pages/Marketing.js";
import Contact from "views/pages/Contact.js";

class AuthRoutes extends React.Component {

  render() {
    return (
      <BrowserRouter>
      <Switch>
        <Route path="/" exact render={props => <Landing {...props} />} />
        <Route
          path="/landing-page"
          exact
          render={props => <Landing {...props} />}
        />
        <Route
          path="/profile-page"
          exact
          render={props => <Profile {...props} />}
        />
        <Route
          path="/contact"
          exact
          render={props => <Contact {...props} />}
        />
        <Route
          path="/portfolio"
          exact
          render={props => <Portfolio {...props} />}
        />
        <Route
          path="/marketing-page"
          exact
          render={props => <Marketing {...props} />}
        />
        <Route
          path="/mobile-page"
          exact
          render={props => <Mobile {...props} />}
        />
        <Route
          path="/web-page"
          exact
          render={props => <Web {...props} />}
        />
        <Route
          path="/dashboard"
          exact
          render={props => <Dashboard {...props} />}
        />
        
        <Redirect to="/dashboard" />
      </Switch>
    </BrowserRouter>
    );
  }
}

export default AuthRoutes;
