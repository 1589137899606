/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import { UncontrolledCarousel } from "reactstrap";

const items = [
  {
    src: require("assets/img/portfolio/redline-website.PNG"),
    altText: '',
    caption: '',
    header: ''
  },
  {
    src: require("assets/img/portfolio/aca.PNG"),
    altText: '',
    caption: '',
    header: ''
  },
  {
    src: require("assets/img/portfolio/redline-logo.png"),
    altText: '',
    caption: '',
    header: ''
  },
  {
    src: require("assets/img/portfolio/draftpool-1.png"),
    altText: '',
    caption: '',
    header: ''
  },
  {
    src: require("assets/img/portfolio/draftpool-2.png"),
    altText: '',
    caption: '',
    header: ''
  },
  {
    src: require("assets/img/portfolio/realagent.png"),
    altText: '',
    caption: '',
    header: ''
  }
];

class Profile extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {

    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 background-detroit">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image centerHeaderImage">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="ni ni-image mr-2 rounded-circle customServiceHeaderImage orangeHeaderImage" />

                        </a>
                      </div>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">

                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">

                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>
                      Dauti Digital
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Windsor, Ontario
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Portfolio
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      View some of our projects
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <UncontrolledCarousel className="redlineLogo" items={items} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Profile;
